<template>
  <th-wrapper class="-mb-8">
    <div class="w-full flex justify-between mb-6">
      <!-- Filter -->
      <filter-header
        class="w-full"
        show-filter
        prune-search-filters
        resource="trash"
        :apply-margin="false"
        :search-filters="filters"
        :parsed-filter-value="parsedFilter"
        @search-filter-submit="handleSubmit"
      >
        <!-- Recover button -->
        <div class="w-full flex flex-row-reverse">
          <el-button
            class="action"
            :disabled="!selectedItems.length"
            plain
            @click="handleBulkRecover"
          >
            {{ $t('pages.settings.trash.recover.multiple') }}
          </el-button>
        </div>
      </filter-header>
    </div>

    <el-row class="header-row" :gutter="10" type="flex" justify="space-evenly">
      <el-col :span="1">
        <el-checkbox
          :model-value="!!selectedItems.length"
          class="header-content"
          @change="handleSelectAll"
        />
      </el-col>
      <el-col :span="7">
        <span class="header-content" v-text="$t('common.titles.name')" />
      </el-col>
      <el-col :span="7">
        <span class="header-content" v-text="$t('common.titles.type')" />
      </el-col>
      <el-col :span="6" />
      <el-col :span="3" />
    </el-row>

    <div
      v-loading="loading"
      class="list"
      :element-loading-text="$t('common.loading.default')"
    >
      <div v-if="!loading && !trashList.length" class="no-data">
        {{ $t('common.data.no_data') }}
      </div>
      <trash-list
        v-else
        :list="trashList"
        :disable-button="!!selectedItems.length"
        :selected="computedSelected"
        :resource-types="resourceTypes"
        @handle-item-select="(checked, item) => handleItemSelect(checked, item)"
        @recover-resource="(item) => recoverResource(item)"
        @paginate="paginate"
      />
    </div>
  </th-wrapper>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import { useCustomersStore } from '@/store/customers'
import { useBranchesStore } from '@/store/branches'
import safeGet from 'just-safe-get'
import flush from 'just-flush'
import debounce from 'debounce'

import FilterHeader from '@/components/filter-header'
import TrashList from './components/trash-list'
import { useProductsStore } from '@/store/products'
import { useMessagesStore } from '@/store/messages'

export default {
  components: {
    FilterHeader,
    TrashList
  },
  setup() {
    const customersStore = useCustomersStore()
    const branchesStore = useBranchesStore()
    const productsStore = useProductsStore()

    return { customersStore, branchesStore, productsStore }
  },
  data() {
    const resourceTypes = {
      accounts: this.$t('pages.settings.trash.types.accounts'),
      branch_groups: this.$t('pages.settings.trash.types.branch_groups'),
      branches: this.$t('pages.settings.trash.types.branches'),
      category_trees: this.$t('pages.settings.trash.types.category_trees'),
      clients: this.$t('pages.settings.trash.types.clients'),
      customers: this.$t('pages.settings.trash.types.customers'),
      device_groups: this.$t('pages.settings.trash.types.device_groups'),
      discounts: this.$t('pages.settings.trash.types.discounts'),
      expense_accounts: this.$t('pages.settings.trash.types.expense_accounts'),
      favourites: this.$t('pages.settings.trash.types.favourites'),
      functions: this.$t('pages.settings.trash.types.functions'),
      manufacturers: this.$t('pages.settings.trash.types.manufacturers'),
      payment_options: this.$t('pages.settings.trash.types.payment_options'),
      pricebooks: this.$t('pages.settings.trash.types.pricebooks'),
      processes: this.$t('pages.settings.trash.types.processes'),
      product_groups: this.$t('pages.settings.trash.types.product_groups'),
      product_service_questions: this.$t(
        'pages.settings.trash.types.product_service_questions'
      ),
      product_templates: this.$t(
        'pages.settings.trash.types.product_templates'
      ),
      products_v1: this.$t('pages.settings.trash.types.products_v1'),
      promotions: this.$t('pages.settings.trash.types.promotions'),
      reasons: this.$t('pages.settings.trash.types.reasons'),
      regions: this.$t('pages.settings.trash.types.regions'),
      safes: this.$t('pages.settings.trash.types.safes'),
      seasons: this.$t('pages.settings.trash.types.seasons'),
      staffs: this.$t('pages.settings.trash.types.staffs'),
      staff_groups: this.$t('pages.settings.trash.types.staff_groups'),
      staff_permission_templates: this.$t(
        'pages.settings.trash.types.staff_permission_templates'
      ),
      tags: this.$t('pages.settings.trash.types.tags'),
      taxes: this.$t('pages.settings.trash.types.taxes'),
      templates: this.$t('pages.settings.trash.types.templates'),
      users: this.$t('pages.settings.trash.types.users'),
      warehouses: this.$t('pages.settings.trash.types.warehouses')
    }
    return {
      loading: false,
      locale: this.$i18n.locale,
      trashList: [],
      selectedItems: [],
      next: null,
      resourceTypes,
      parsedFilter: {},
      filters: [
        {
          name: 'type',
          type: 'select',
          label: this.$t('common.titles.type'),
          options: this.parseResourceTypes(resourceTypes),
          filterable: true
        }
      ]
    }
  },
  computed: {
    computedSelected() {
      return this.selectedItems.map((item) => item.id)
    },
    timeZone() {
      return (
        safeGet(
          this.$store.getters['Config/getClientAccountConfiguration'],
          'settings.default_timezone'
        ) || 'Europe/Berlin'
      )
    }
  },
  mounted() {
    this.fetch()
  },
  methods: {
    async fetch(filters) {
      const query = filters || {}
      try {
        this.loading = true
        const { data, next } = await th.trash().getAll({ limit: 50, query })
        this.trashList = data
        this.next = next || null
      } catch (err) {
        this.$logException(err, { trackError: false })
      } finally {
        this.loading = false
      }
    },
    paginate: debounce(
      async function () {
        if (this.next) {
          try {
            this.loading = true
            const { data, next } = await this.next()
            this.trashList = this.trashList.concat(data)
            this.next = next || null
          } catch (err) {
            this.$logException(err, { trackError: false })
          } finally {
            this.loading = false
          }
        }
      },
      500,
      true
    ),
    clear() {
      this.trashList = []
      this.selectedItems = []
    },
    refresh() {
      this.$nextTick(() => {
        this.clear()
        this.fetch()
        this.updateResourcesCount()
      })
    },
    async recoverResource(item) {
      const successMessage = this.$t('pages.trash.messages.recover_success')
      const errorMessage = this.$t('pages.trash.messages.recover_fail')

      const query = { resource: item.id, type: item.type }

      try {
        await th.trash().recover({ query })
        this.$message({
          type: 'success',
          message: successMessage
        })
        this.refresh()
      } catch (err) {
        this.$logException(err, {
          message: errorMessage
        })
      }
    },
    handleBulkRecover() {
      const inst = th.trash()

      const operations = this.selectedItems.map((item) => {
        if (item.type === 'products_v1' && !this.shouldRecheckProductsCount) {
          this.shouldRecheckProductsCount = true
        }
        if (item.type === 'customers' && !this.shouldRecheckCustomersCount) {
          this.shouldRecheckCustomersCount = true
        }
        const query = { resource: item.id, type: item.type }
        return () => inst.recover({ query })
      })

      const label = 'Recovering items.'

      const currentRoute = this.$route.fullPath

      const fulfillment = () => {
        if (this.$route.fullPath === currentRoute) {
          this.$message({
            type: 'success',
            message: this.$t('pages.trash.messages.recover_success')
          })
          this.refresh()
        }
      }

      useMessagesStore().startLocalOperation({
        operations,
        label,
        fulfillment
      })
    },
    handleItemSelect(nextCheck, item) {
      if (nextCheck) {
        this.selectedItems.push(item)
        return
      }
      this.selectedItems = this.selectedItems.filter(
        (selected) => selected.id !== item.id
      )
    },
    handleSelectionChange(val) {
      this.selectedItems = val
    },
    handleSelectAll(nextCheck) {
      if (nextCheck) {
        return this.handleSelectionChange(this.trashList)
      }
      this.handleSelectionChange([])
    },
    handleSubmit(filterObject) {
      const filters = {}

      for (const filter of Object.entries(filterObject)) {
        const [key, valueObj] = filter
        const filterValue =
          safeGet(valueObj, 'originalData.queryInput') || valueObj.value
        filters[key] = filterValue
      }

      this.parsedFilter = filters
      this.fetch(flush(filters))
    },
    parseResourceTypes(typesObject) {
      return Object.entries(typesObject).map((resource) => {
        const [value, label] = resource
        return { value, label }
      })
    },
    updateResourcesCount() {
      if (!this.productsStore.productsCount) {
        this.productsStore.checkProductsCount()
      }
      if (!this.customersStore.customersCount) {
        this.customersStore.checkCustomersCount()
      }
      if (!this.branchesStore.branchesCount) {
        this.branchesStore.checkBranchesCount()
      }
    }
  }
}
</script>

<style scoped>
.action {
  height: var(--action-button-height);
}
.header-row {
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
  border-top: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
}

.header-content {
  min-height: 28px;
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  @apply text-th-primary-gray;
  font-size: 14px;
  font-weight: bold;
}

.list {
  height: calc(100vh - 24.5rem);
  overflow-y: auto;
}

.no-data {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
</style>
