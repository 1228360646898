<template>
  <dynamic-scroller
    class="h-full"
    :items="computedList"
    :min-item-size="57"
    key-field="id"
    @scroll="onScroll"
  >
    <template #default="{ item, active }">
      <dynamic-scroller-item
        :item="item"
        :active="active"
        :size-dependencies="[]"
        :data-index="item.id"
      >
        <el-row class="item" :gutter="10" type="flex" justify="space-evenly">
          <el-col :span="1">
            <el-checkbox
              v-model="item.checked"
              @change="$emit('handle-item-select', $event, item)"
            />
          </el-col>
          <el-col :span="7">
            <span>{{ item.name || '–' }}</span>
          </el-col>
          <el-col :span="7">
            <span>{{ resourceTypes[item.type] }}</span>
          </el-col>
          <el-col :span="6">
            <span>{{ formatUpdatedAt(item) }}</span>
          </el-col>
          <el-col :span="3">
            <div class="text-right">
              <el-button
                class="button-mini"
                type="primary"
                plain
                :disabled="disableButton"
                icon="RefreshLeft"
                @click="$emit('recover-resource', item)"
              >
                {{ $t('pages.settings.trash.recover.title') }}
              </el-button>
            </div>
          </el-col>
        </el-row>
      </dynamic-scroller-item>
    </template>
  </dynamic-scroller>
</template>

<script>
import safeGet from 'just-safe-get'
import { DynamicScroller, DynamicScrollerItem } from 'vue3-virtual-scroller'

export default {
  components: {
    DynamicScroller,
    DynamicScrollerItem
  },
  props: {
    list: {
      type: Array,
      required: true
    },
    selected: {
      type: Array,
      default: () => []
    },
    disableButton: {
      type: Boolean,
      default: false
    },
    resourceTypes: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    computedList() {
      return this.list.map((item) => {
        item.checked = this.selected.includes(item.id)
        return item
      })
    }
  },
  methods: {
    formatUpdatedAt(row) {
      const date = safeGet(row, 'updated_at.iso')
      return date ? this.$date.formatDateTime(date) : '--'
    },
    onScroll(event) {
      if (
        event.target.scrollTop + event.target.offsetHeight >=
        event.target.scrollHeight
      ) {
        this.$emit('paginate')
      }
    }
  }
}
</script>

<style scoped>
.item {
  font-size: 14px;
  padding: 0.5rem 0;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--border-color);
}
</style>
